<template>
  <div class="pa-4">
    <div><v-img src="@/assets/hero_main_2x.png" /></div>
    <div>
      <ThreeLine instyle="color: #3C4043"
        ><template v-slot:heading>Your online digital debt help tool. </template
        ><template v-slot:content
          >Our user-friendly online debt service is designed to make getting the
          support you need available to you, in a convenient and stress-free
          manner, just for you.
        </template></ThreeLine
      >
    </div>
    <div class="pt-2">
      <J3Card :icon="require('@/assets/graphic_icon_data_.png')">
        <v-row no-gutters>
          <v-col cols="12" class="px-1">
            <ThreeLine
              ><template v-slot:heading>How it all works. </template
              ><template v-slot:content>
                <p>
                  Typically, you can finish this in 15 to 30 minutes, on
                  average.
                </p>

                <p>
                  We guide you through each section, breaking it down step by
                  step, helping you paint a clear picture of your current
                  financial situation.
                </p>

                <p>
                  Providing details of your income and expenditure will give a
                  clear indication of your disposable income and clearly
                  identify what you can actually afford to repay each month.
                </p>

                <p>
                  Based on your own unique profile, we will then present a list
                  of solutions tailored precisely to your own situation.
                </p>

                <p>
                  Should you feel one of our solutions is right for you, we
                  would then need a bit more information to progress your
                  application.
                </p>

                <p>
                  If our solution is not the right fit, don’t worry, we will
                  direct you to other resources where you will be able to find
                  the help you need. Please be aware – you need to be over 18 to
                  apply.
                </p>

                <div class="cta1 pa-5" elevation="2">
                  <v-img
                    :src="require('@/assets/graphic_scale_with_dot.png')"
                    width="100%"
                  />
                  <v-chip
                    dark
                    color="#4CB4D4"
                    style="
                      position: absolute;
                      top: 50px;
                      left: calc(50% - 57px);
                    "
                    >TAKE ACTION</v-chip
                  >
                  <div class="font-weight-thin tagline">
                    Start getting your finances under control
                  </div>
                  <v-img
                    :src="require('@/assets/booster_help_text_1.svg')"
                    width="189px"
                    absolute
                    top
                    center
                    contain
                    style="
                      position: absolute;
                      top: 96px;
                      left: calc(50% - 94px);
                    "
                  />
                  <div class="text-center" v-show="false">
                    <div
                      v-if="chipSize == `x-small`"
                      class="my-2"
                      color="white"
                      style="
                        background: #fff;
                        padding: 5px 0px;
                        border-radius: 16px;
                        font-size: 0.75rem;
                      "
                    >
                      Bonus: this&nbsp;
                      <b>won't affect your credit score!</b>
                    </div>
                    <v-chip v-else class="my-2" color="white"
                      >Bonus: this&nbsp;
                      <b>won't affect your credit score!</b>
                    </v-chip>
                  </div>
                  <v-btn
                    class="mt-2 rounded-j3 cta"
                    large
                    block
                    color="#FED833"
                    @click="loader = 'loading'"
                    :to="{
                      name: 'home',
                      params: {},
                    }"
                    >Click to start
                  </v-btn>
                </div>
              </template></ThreeLine
            >
          </v-col>
        </v-row>
      </J3Card>
    </div>
    <div style="height: 40px" v-show="false"></div>
    <div class="pt-1">
      <J3Card :icon="require('@/assets/graphic_icon_lock_.png')" v-show="false">
        <v-row no-gutters>
          <v-col cols="12" class="px-1">
            <ThreeLine
              ><template v-slot:heading
                >Say hello to our friendly new debt tool! </template
              ><template v-slot:content>
                <p>
                  Teaming up with Experian, we’ve created a simple way for you
                  to share your debt details online—it’s fast, easy, and
                  absolutely free.
                </p>

                <p>
                  Just share your name, date of birth, and your addresses from
                  the last three years with us.
                </p>

                <p>
                  Your information will be sent securely to Experian for a soft
                  search, no need to worry about it affecting your credit file.
                </p>

                <p>
                  Experian will then send us the nitty-gritty details of your
                  debts—creditors, current balances, and reference numbers.
                </p>

                <p>
                  No more digging through paperwork! This ensures the advice we
                  provide is spot-on and tailored just for you.
                </p>
              </template></ThreeLine
            >
          </v-col>
        </v-row>
      </J3Card>
    </div>
    <div style="height: 20px" v-show="false"></div>
    <div class="pt-1">
      <J3Card
        :icon="require('@/assets/graphic_icon_search_.png')"
        v-show="false"
      >
        <v-row no-gutters>
          <v-col cols="12" class="px-1">
            <ThreeLine
              ><template v-slot:heading>How it all works. </template
              ><template v-slot:content>
                <p>
                  Typically, you can finish this in 15 to 30 minutes on average.
                </p>

                <p>
                  We guide you through each section, breaking it down step by
                  step, helping you paint a clear picture of your financial
                  situation.
                </p>

                <p>
                  Share details about your income and expenses with us and then
                  see your personalised budget profile come to life.
                </p>

                <p>
                  Based on your unique profile, we present a list of solutions
                  tailored precisely to your situation.
                </p>

                <p>
                  Should you choose one of our solutions, we’ll need a bit more
                  info to finalise your application.
                </p>

                <p>
                  If our solution isn’t the right fit, we’ll direct you to other
                  resources where you can find the help you need.
                </p>
                <v-btn
                  class="mt-2 rounded-j3 cta"
                  large
                  block
                  color="#FED833"
                  :to="{
                    name: 'home',
                    params: {},
                  }"
                >
                  Start
                </v-btn>
              </template></ThreeLine
            >
          </v-col>
        </v-row>
      </J3Card>
    </div>
    <div style="height: 20px"></div>
    <div class="pt-1">
      <J3Card :icon="require('@/assets/graphic_icon_ipad_.png')">
        <v-row no-gutters>
          <v-col cols="12" class="px-1">
            <ThreeLine
              ><template v-slot:heading
                ><div style="display: block; height: 50px"></div>
                Why people use our debt help Booster tool </template
              ><template v-slot:content>
                <p>
                  See the example below which details how a Trust Deed can help
                  you consolidate unaffordable payments into one manageable
                  payment, and provide you with debt forgiveness, and
                  protection, from your creditors.
                </p>
                <br />

                <span class="stats_num" style="text-transform: uppercase">
                  Debt example*
                </span>

                <v-container class="pa-0" justify-center align-center>
                  <div class="dotted-line"></div>
                  <v-row no-gutters justify="space-between" align="center">
                    <v-col cols="auto">
                      <v-img
                        :src="require('@/assets/gicon1.svg')"
                        width="25"
                        class="center-image"
                    /></v-col>
                    <v-col
                      style="line-height: 36px; padding-left: 15px"
                      cols="auto"
                      class="mr-auto"
                    >
                      Unsecured Loans</v-col
                    ><v-col cols="auto">
                      <div class="gbox1">£16,777</div></v-col
                    >
                  </v-row>
                  <v-row
                    no-gutters
                    justify="space-between"
                    align="center"
                    class="pt-2"
                  >
                    <v-col cols="auto">
                      <v-img
                        :src="require('@/assets/gicon2.svg')"
                        width="25"
                        class="center-image"
                    /></v-col>
                    <v-col
                      style="line-height: 36px; padding-left: 15px"
                      cols="auto"
                      class="mr-auto"
                    >
                      Credit Cards</v-col
                    ><v-col cols="auto">
                      <div class="gbox1">£10,523</div></v-col
                    >
                  </v-row>
                  <v-row
                    no-gutters
                    justify="space-between"
                    align="center"
                    class="pt-2"
                  >
                    <v-col cols="auto">
                      <v-img
                        :src="require('@/assets/gicon3.svg')"
                        width="25"
                        class="center-image"
                    /></v-col>
                    <v-col
                      style="line-height: 36px; padding-left: 15px"
                      cols="auto"
                      class="mr-auto"
                    >
                      Council debt</v-col
                    ><v-col cols="auto"> <div class="gbox1">£1,391</div></v-col>
                  </v-row>
                  <v-row
                    no-gutters
                    justify="space-between"
                    align="center"
                    class="pt-2"
                  >
                    <v-col cols="auto">
                      <v-img
                        :src="require('@/assets/gicon4.svg')"
                        width="25"
                        class="center-image"
                    /></v-col>
                    <v-col
                      style="line-height: 36px; padding-left: 15px"
                      cols="auto"
                      class="mr-auto"
                    >
                      Overdrafts</v-col
                    ><v-col cols="auto"> <div class="gbox1">£999</div></v-col>
                  </v-row>
                  <v-row
                    no-gutters
                    justify="space-between"
                    align="center"
                    class="pt-2"
                  >
                    <v-col cols="auto">
                      <v-img
                        :src="require('@/assets/gicon5.svg')"
                        width="25"
                        class="center-image"
                    /></v-col>
                    <v-col
                      style="line-height: 36px; padding-left: 15px"
                      cols="auto"
                      class="mr-auto"
                    >
                      Debt Collection agencies</v-col
                    ><v-col cols="auto"> <div class="gbox1">£526</div></v-col>
                  </v-row>
                  <div class="dotted-line"></div>
                  <v-row no-gutters justify="space-between" align="center">
                    <v-col
                      style="
                        line-height: 36px;
                        color: #dd8158 !important;
                        font-weight: 600;
                      "
                      cols="auto"
                      class="mr-auto"
                    >
                      TOTAL DEBT</v-col
                    ><v-col cols="auto">
                      <div class="gbox1 big">£30,216</div></v-col
                    >
                  </v-row>
                  <v-row
                    no-gutters
                    justify="space-between"
                    align="center"
                    class="pt-4"
                  >
                    <v-col
                      style="color: #dd8158 !important; font-weight: 600"
                      cols="auto"
                      class="mr-auto"
                    >
                      YOUR CURRENT<BR /> MONTHLY REPAYMENTS</v-col
                    ><v-col cols="auto">
                      <div class="gbox1 big">£487</div></v-col
                    >
                  </v-row>
                  <div class="dotted-line"></div>
                  <v-row no-gutters justify="space-between" align="center">
                    <v-col
                      style="color: #4ea0c5 !important; font-weight: 600"
                      cols="auto"
                      class="mr-auto"
                    >
                      YOUR NEW MONTHLY<br />
                      IVA PAYMENT</v-col
                    ><v-col cols="auto">
                      <div class="gbox1 big second">£135</div></v-col
                    >
                  </v-row>
                  <v-row
                    no-gutters
                    justify="space-between"
                    align="center"
                    class="pt-4"
                  >
                    <v-col
                      style="color: #4ea0c5 !important; font-weight: 600"
                      cols="auto"
                      class="mr-auto"
                    >
                      DEBT WRITE OFF</v-col
                    ><v-col cols="auto">
                      <div class="gbox1 big second">73%</div></v-col
                    >
                  </v-row>
                  <div class="dotted-line"></div>

                  <p class="text-body-2 pa-0 ma-0">
                    *This is an example, all payment reductions and debt write
                    of percentages are based on an individual’s personal
                    circumstances and may be higher, or may be lower – dependent
                    on final disposable income.
                  </p>
                  <br /><br /><br />
                  <v-btn
                    class="mt-2 rounded-j3 cta"
                    large
                    block
                    color="#FED833"
                    :to="{
                      name: 'home',
                      params: {},
                    }"
                  >
                    Click to Start
                  </v-btn>
                </v-container>
              </template></ThreeLine
            >
          </v-col>
        </v-row>
      </J3Card>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import ThreeLine from "../components/j3/ThreeLine.vue";
import J3Card from "../components/j3/J3Card.vue";

export default {
  name: "Step0",
  components: { ThreeLine, J3Card },
  props: {
    market: String,
  },
  data() {
    return {
      chipSize: "normal", // Default size
    };
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods: {
    ...mapActions(["hideLogo", "updateUTM"]),
    handleResize() {
      this.chipSize = window.innerWidth < 430 ? "x-small" : "normal";
    },
  },
  created() {
    this.$store.dispatch("hideLogo");
    const queryParams = new URLSearchParams(window.location.search);

    this.$store.dispatch("updateUTM", {
      utm_source: queryParams.get("utm_source"),
      utm_medium: queryParams.get("utm_medium"),
      utm_campaign: queryParams.get("utm_campaign"),
      utm_term: queryParams.get("utm_term"),
    });

    this.$store.dispatch("updateMarket", { market: this.market });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
<style lang="scss">
.cta1 {
  position: relative;
  background: linear-gradient(24deg, #58b3dd 0%, #2c5a6f 100%) 0% 0% no-repeat
    padding-box;
  width: 100%;
  height: auto;
  box-shadow: 0px 6px 12px #566f85;

  border-radius: 7px;
}

.cta1 {
  .v-chip .v-chip__content {
    height: auto !important;
  }
}
.cta1 .tagline {
  position: absolute;
  top: 130px;
  color: #fff;
  left: calc(50% - 134px);
  font-size: 0.875rem;

  @media (max-width: 423px) {
    position: relative;
    left: 0;
    top: 0;
    text-align: center;
    margin-top: 10px;
    font-size: 0.8rem;
  }
}

.stats_num {
  font-weight: 600;
  color: #58b3dd;
}

.theme--light.v-card {
  color: #3c4043;
}
.theme--light.v-btn {
  color: #3c4043;
}
.dotted-line {
  border-top: 2px dashed #dadbdf; /* Adjust color as needed */
  width: 100%;
  margin: 14px 0;
}
.gbox1 {
  display: inline-box;
  background-color: #ebecf0;
  padding: 8px 0px;
  border-radius: 8px;
  font-weight: 600;
  vertical-align: center;
  width: 70px;
  text-align: center;
  font-size: 12px;
}
.gbox1.big {
  background-color: #ffc5c3;
  padding: 9px;
  font-size: 15px;
  min-width: 90px;
}
.gbox1.big.second {
  background-color: #4ea0c5;
  color: #fff;
}
</style>
